<template>
    <v-card class="elevation-12">
        <v-toolbar color="primary" dark dense>
            <v-toolbar-title> Connectez-vous </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-4">

            <p v-if="isSessionExpired">
                Votre session à expirée, vous pouvez choisir de rester sur cette page ou vous pouvez vous reconnecter.
            </p>

            <ValidationObserver ref="observer">
                <ValidationProvider v-slot="{ errors }" name="Client" rules="required">
                    <v-autocomplete v-show="$store.state.application.localEnv" v-model="providerCode" :items="providers"
                        placeholder="Client" prepend-icon="fas fa-users" menu-props="offsetY" :error-messages="errors"
                        outlined dense />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
                    <v-text-field v-model="email" placeholder="Email" prepend-icon="fas fa-envelope"
                        @keydown.enter="submit" :error-messages="errors" outlined dense />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" name="Mot de passe" rules="required">
                    <v-text-field v-model="password" placeholder="Mot de passe" @keydown.enter="submit"
                        prepend-icon="fas fa-key" :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'" :error-messages="errors"
                        outlined dense />
                </ValidationProvider>
            </ValidationObserver>

            <v-row align="center" no-gutters>
                <v-col class="text-right">
                    <ForgottenPasswordModal :initEmail="email">
                        <template v-slot:activator="{ on }">
                            <a v-on="on"> Mot de passe oublié ? </a>
                        </template>
                    </ForgottenPasswordModal>
                </v-col>
            </v-row>

        </v-card-text>

        <v-card-actions>
            <v-btn v-if="isSessionExpired" @click="$emit('close')" small> Rester </v-btn>

            <v-spacer />

            <v-btn @click="submit()" color="primary" small> Connexion </v-btn>
        </v-card-actions>

        <v-row class="mt-2" no-gutters v-if="loginSubMessage">
            <v-col>
                <div class="text-center align-self-center px-2" v-html="loginSubMessage"></div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import ForgottenPasswordModal from './ForgottenPasswordModal.vue';

export default {
    name: 'BuyerLoginCard',

    props: {
        isSessionExpired: { type: Boolean, default: false }
    },

    components: {
        ForgottenPasswordModal
    },

    data: () => ({
        email: '',
        password: '',
        loginSubMessage: '',

        showPassword: false,

        providers: []
    }),

    methods: {
        async submit() {
            try {
                const valid = await this.$refs.observer.validate();
                if (!valid) {
                    return;
                }

                this.setLoading(true);
                const body = {
                    type: 'buyer',
                    login: this.email,
                    password: this.password,
                    providerCode: this.providerCode
                };
                const { success, err } = await this.repos.auth.login(body);
                if (success) {
                    this.$notify({
                        title: 'Bienvenue',
                        text: 'Bienvenue sur votre espace client',
                        type: 'success'
                    });

                    if (this.isSessionExpired) {
                        this.$emit('close');
                    } else {
                        if (this.$route.query.redirect) {
                            this.$router.push({ path: this.$route.query.redirect, hash: this.$route.query.hash });
                        } else {
                            this.$router.push('/');
                        }
                    }
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: err.message,
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async fetchProviders() {
            try {
                const {
                    providers
                } = await this.repos.providers.getAvailables();
                this.providers = providers;
            } catch (err) {
                console.error(err);
            }
        }
    },

    computed: {
        providerCode: {
            get() {
                return this.$store.state.application.providerCode;
            },

            set(providerCode) {
                this.$store.commit('application/setProviderCode', providerCode);
            }
        }
    },

    created() {
        this.fetchProviders();
        this.loginSubMessage = this.getConfig('login.sub_message', '');
    }
};
</script>