<template>
    <v-dialog v-model="isOpen" max-width="550">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-card-title class="primary py-1 subtitle white--text">
                Mot de passe oublié
            </v-card-title>

            <v-card-text>
                <template v-if="emailSent">
                    <p class="pt-4">
                        Un email contenant un lien de réinitialisation vous a été envoyé.
                    </p>
                </template>
                <template v-else>
                    <p class="pt-4">
                        Saisissez votre adresse e-mail et nous vous enverrons un lien de réinitialisation.
                    </p>

                    <v-text-field v-model="email" label="Email" prepend-icon="fas fa-envelope" type="text" outlined dense hide-details />
                </template>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn v-if="!emailSent" @click="submit" color="primary" small> Envoyer </v-btn>
                <v-btn v-if="emailSent" @click="isOpen = false" small> Fermer </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        initEmail: { type: String }
    },

    data: () => ({
        email: '',
        isOpen: false,
        emailSent: false
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const body = {
                    type: 'buyer',
                    email: this.email,
                    providerCode: this.$store.state.application.providerCode
                };
                const { success, err } = await this.repos.auth.forgotPassword(body);
                if (success) {
                    this.emailSent = true;
                    this.$notify({
                        title: 'Information',
                        text: 'Un email contenant un lien de réinitialisation vous a été envoyé',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue durant l\'envoi du lien de réinitialisation',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen() {
            this.emailSent = false;
            if (this.isOpen && this.initEmail) {
                this.email = this.initEmail;
            }
        }
    }
};
</script>